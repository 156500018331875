import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";

import { Wrapper } from "../components/layout-components";
import SvgLoader from "../components/svg-loader";
import Button from "../components/globals/button";
import PageHead from "../components/globals/page-head";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicThankYou: {
      data: { body },
    },
  },
}) {
  return (
    <Grid>
      <PageHead title="Thank You" />
      <SvgLoader icon="IconLargeCheck" />
      {body.raw && <RichText render={body.raw} />}
      <Button to="/" text="Return to Site" color="black" />
    </Grid>
  );
}

const Grid = styled(Wrapper)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.2;
    max-width: 8em;
    text-align: inherit;
  }

  p {
    max-width: 25em;
    margin-bottom: 3rem;
    line-height: 2;
  }

  ul {
    list-style: initial;
    padding-left: 1rem;

    li {
      margin-bottom: 0.5rem;
    }
  }
`;

/**
 * pageQuery
 */
export const thanksQuery = graphql`
  query ThanksPage($uid: String!) {
    prismicThankYou(uid: { eq: $uid }) {
      uid
      data {
        title
        body {
          raw
        }
      }
    }
  }
`;
